$("#burger-container").on("click", function (e) {
  e.stopPropagation();
  $(this).toggleClass("open");
  $(".menu-container li.has-submenu").removeClass("open");
  $(".menu-container").toggleClass("open");
});

function mobileFont() {
  $("span").each(function () {
    if ($(this).is("[style]") && $(window).width() < 776) {
      var fontSize = parseInt($(this).css("font-size")) / 1.65 + "px";
      $(this).css("font-size", fontSize);
    }
  });
}
window.onload = mobileFont();

function squareHeight() {
  var squareHeight = parseInt($(".square").css("width")) * 0.566 + "px";
  $(".square").css("height", squareHeight);
}
window.onload = squareHeight();

$(window).scroll(function () {
  //if I scroll more than 1000px...
  if ($(window).scrollTop() < 300) {
    $("body").addClass("with-after");
  } else {
    $("body").removeClass("with-after");
  }
});

new WOW().init();
$(document).ready(function () {
  $(".flexslider").flexslider({
    directionNav: false,
  });
  $(".flex-control-nav").addClass("container");
});
$(document).ready(function () {
  $(".my-slick").slick({
    autoplay: false,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          centerMode: true,
          centerPadding: "60px",
        },
      },
    ],
  });
});

$(document).ready(function () {
  $(".choose-menu").slick({
    autoplay: false,
    slidesToShow: 3,
    centerPadding: "60px",
    slideCount: [
      {
        breakpoint: 3,
        settings: {
          centerMode: true,
        }
      }
    ],
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          centerMode: true,
          centerPadding: "60px",
        },
      },
    ],
  });
});

$(".show-all").click(function () {
  $(".more-cities").toggleClass("d-none");
  $(".more-cities").toggleClass("wow fadeInUp animated");
});

// $('.calculator-input').change(validateForm)

var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  var headerHeight = parseInt($('.upper-nav').css('height'));
  if (prevScrollpos < currentScrollPos && currentScrollPos > 110) {
    document.querySelector("header").style.top = headerHeight * -1 + "px";
    $(".menu-container").addClass("scroll-down");
  } else {
    document.querySelector("header").style.top = "0px";
    $(".menu-container").removeClass("scroll-down");
  }
  prevScrollpos = currentScrollPos;
};
